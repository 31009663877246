<template>
  <v-container class="text-center">
    <br />
    <v-row>
      <v-col cols="12" md="12">
        <v-card>
          <div class="text-center">
            <h2 class="titulo text-center" v-text="'Solicitud de Prestamo ' + (items.general ? items.general.id :  '')" />
          </div>
          <div class="text-right itemsTop">
            <v-tooltip top
              ><template v-slot:activator="{ on, attrs }"><v-icon class="mr-2 mt-3" size="22" color="success" dark v-bind="attrs" v-on="on" @click="openModal(true)">mdi-check-bold</v-icon></template
              ><span v-text="'Aceptar solicitud'"
            /></v-tooltip>
            <v-tooltip top
              ><template v-slot:activator="{ on, attrs }"><v-icon class="mr-2 mt-3" size="22" color="error" dark v-bind="attrs" v-on="on" @click="openModal(false)">mdi-close-thick</v-icon></template
              ><span v-text="'Rechazar solicitud'"
            /></v-tooltip>
          </div>
          <v-row>
            <v-spacer></v-spacer>
            <v-col cols="4" md="4">
              <v-progress-circular :rotate="360" :size="100" :width="15" :value="perMoney" color="primary"> {{ perMoney.toFixed(2) }}% </v-progress-circular>
            </v-col>
            <v-col cols="4" md="4">
              Nombre: {{ items.general ? items.general.persona.nombre + " " + items.general.persona.apellido_paterno + " " + items.general.persona.apellido_materno : "" }} <br />
              Monto sugerido: $ {{ items.general ? items.general.monto_sugerido : '' }} <br />
              Monto solicitado: $ {{ items.general ? items.general.monto_solicitado : '' }} <br />
              <span v-if="items.general.monto_aprobado">
              {{ items.general ? " Monto autorizado: $ " + items.general.monto_aprobado : "" }} <br v-if="items.general.monto_aprobado" />
              </span>
                  <span v-if="items.general.monto_entregado">
              {{ items.general ? " Monto entregado: $ " + items.general.monto_entregado : "" }} <br v-if="items.general.monto_entregado" />
              </span>
            </v-col>
            <v-spacer></v-spacer>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6" md="6">
        <Resume-Card title="Detalle de prestamo" :items="items.general.persona" :detailItems="detail.items" :keys="loan.details" :omitItems="blackList.items" :loanId="loanId" :complete="requestSections.datos_solicitante" :section="1"></Resume-Card>
        <br />
        <Resume-Card title="Ingresos" :items="items.ingresos" :keys="loan.income" :omitItems="blackList.loan" :loanId="loanId" :complete="requestSections.ingresos_promedios" :section="7"></Resume-Card>
        <br />
        <Resume-Card title="Observaciones" :items="items.observaciones" :keys="loan.observation" :omitItems="blackList.loan" :loanId="loanId" :complete="requestSections.observaciones" :section="9"></Resume-Card>
        <div class="text-right" v-if="items.prendas.length > 1">
          <v-btn text color="primary" @click="view.pledges = !view.pledges"> Mostrar {{ (view.pledges ? "menos" : "todas las prendas") + " (" + items.prendas.length + ")" }} </v-btn>
        </div>
        <br v-else />
        <div v-for="(item, i) in items.prendas.slice(0, view.pledges ? items.prendas.length : 1)" :key="i">
          <Resume-Card :title="'Prenda ' + (i + 1)" :items="item.prenda" :keys="loan.pledge" :loanId="loanId" :complete="requestSections.prendas" :section="8"></Resume-Card>
          <br />
        </div>
        <Resume-Card :title="'Persona con ingresos ' + (items.persona_ingreso ? items.persona_ingreso.parentesco : '')" :items="items.persona_ingreso ? items.persona_ingreso.persona : []" :keys="loan.details" :loanId="loanId" :complete="requestSections.persona_ingresos" :section="4"></Resume-Card>
        <br />
      </v-col>
      <v-col cols="6" md="6">
        <Resume-Card title="Direccion de persona" :items="items.general.direccion_persona" :keys="loan.address" :loanId="loanId" :complete="requestSections.datos_solicitante" :section="2"></Resume-Card>
        <br />
        <Resume-Card title="Empleo" :items="items.general.empleo" :keys="loan.employee" :loanId="loanId" :section="2"></Resume-Card>
        <br />
        <Resume-Card title="Direccion de empleo" :items="items.general.direccion_empleo" :keys="loan.address" :loanId="loanId" :complete="requestSections.datos_solicitante" :section="3"></Resume-Card>
        <div class="text-right" v-if="items.referencias_personales.length > 1">
          <v-btn text color="primary" @click="view.income = !view.income"> Mostrar {{ (view.income ? "menos" : "todas") + " (" + items.referencias_personales.length + ")" }} </v-btn>
        </div>
        <br v-else />
        <div v-for="(item, i) in items.referencias_personales.slice(0, view.income ? items.referencias_personales.length : 1)" :key="i">
          <Resume-Card :title="'Referencia de ' + item.parentesco" :items="item.persona" :keys="loan.details" :loanId="loanId" :complete="requestSections.referencias_personales" :section="6"></Resume-Card>
        </div>
        <div class="text-right" v-if="items.avales.length > 1">
          <v-btn text color="primary" @click="view.aval = !view.aval"> Mostrar {{ (view.aval ? "menos" : "todos") + " (" + items.avales.length + ")" }} </v-btn>
        </div>
        <br v-else />
        <div v-for="(item, i) in items.avales.slice(0, view.aval ? items.avales.length : 1)" :key="i">
          <Resume-Card :title="'Aval ' + (i + 1)" :items="item.persona" :keys="loan.details" :loanId="loanId" :complete="requestSections.avales" :section="5"> </Resume-Card>
          <br />
        </div>
      </v-col>
    </v-row>
    <Dialog-Form :estate="dialogs.send" :title="(modalType ? 'Aprobar' : 'Rechazar') + ' solicitud'">
      <template v-slot:buttons>
        <Basic-Btn v-if="questionMode" text="Cancelar" color="error" icon="mdi-close" @click="cancel()" :loading="loading" />
        <Basic-Btn v-if="questionMode" text="Si" color="success" icon="mdi-check" @click="questionMode = false" :loading="loading" />
        <Basic-Btn v-if="!questionMode" text="Atras" color="disabled" icon="mdi-close" @click="questionMode = true" :loading="loading" />
        <Basic-Btn v-if="!questionMode" :text="modalType ? 'Aprobar' : 'Rechazar'" color="success" icon="mdi-check" @click="getValidate()" :loading="loading" />
      </template>
      <v-form ref="formSend" lazy-validation class="text-center">
        <div v-if="questionMode">
          <h2>
            ¿Esta seguro que desea <b>{{ modalType ? "aprobar" : "rechazar" }}</b> esta solicitud?
          </h2>
        </div>
        <v-row v-if="!questionMode">
          <v-col cols="12" md="12">
            <v-text-field v-model="forms.send.comentarios" label="Comentario" prepend-icon="mdi-card-text-outline" :rules="rules.comentarios" :disabled="loading" outlined dense required></v-text-field>
          </v-col>
          <v-col cols="12" md="12" class="text-center pt-0">
            <div>
              <h2 class="text-center" v-text="'Rebajes'" ></h2>
            </div>
            <br>
            <div :class="auxValue.length == 0 ? 'text-center': 'text-right'">
              <Basic-Btn text="Eliminar" color="error" icon="mdi-delete" @click="deleteGlobal()" v-if="deleteGlobals" :loading="loading" />
              <Basic-Btn :text="deleteGlobals ? 'Cancelar' : 'Eliminar'" :color="deleteGlobals ? 'disabled' : 'error'" :icon="deleteGlobals ? 'mdi-close' : 'mdi-delete'" @click="deleteGlobals = !deleteGlobals" :loading="loading" :disabled="auxValue == 0" />
              <Basic-Btn text="Agregar" color="primary" icon="mdi-plus" @click="addGlobal()" :loading="loading" :disabled="auxValue.length == 50" />
            </div>
          </v-col>
          <v-col v-for="(data, index) in auxValue" :key="index" cols="12" md="12">
            <v-row>
              <v-col cols="1" md="1" v-if="deleteGlobals">
                <v-checkbox class="ma-0" v-model="auxValue[index].delete" color="secondary" hide-details></v-checkbox>
              </v-col>
              <v-col cols="6" md="6">
                <v-text-field hide-details v-model.number="auxValue[index].tipo" :label="'Tipo ' + (index + 1)" :prepend-icon="'mdi-numeric-' + (index > 8 ? '9-plus' : index + 1) + '-circle-outline'" :disabled="loading" outlined dense></v-text-field>
              </v-col>
              <v-col :cols="deleteGlobals ? 5 : 6" :md="deleteGlobals ? 5 : 6">
                <v-text-field hide-details v-model="auxValue[index].monto" :label="'Monto ' + (index + 1)" prepend-icon="mdi-information-outline" :disabled="loading" outlined dense></v-text-field>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-form>
    </Dialog-Form>
        <Alerts-Message :estate="dialogs.alert" :alerts="alertData">
      <template v-slot:close>
        <Basic-Btn text="Cancelar" color="disabled" @click="dialogs.alert = false" block :loading="loading" />
      </template>
      <template v-slot:after>
        <Basic-Btn text="Continuar" color="primary" @click="save()" block :loading="loading" />
      </template>
    </Alerts-Message>
    <BasicAlert :active.sync="alert.active" :msg="alert.msg" :color="alert.color" />
  </v-container>
</template>

<script>
import "animate.css";
import services from "@/utils/services";
import { ResumeCard, DialogForm, BasicAlert, BasicBtn, AlertsMessage, } from "@/components";

export default {
  name: "detalle-solicitud",
  components: {
    ResumeCard,
    DialogForm,
    BasicAlert,
    BasicBtn,
    AlertsMessage,
  },
  data: () => ({
    loanId: 0,
    alertData: [],
    alert: {
      active: false,
      color: "error",
      msg: "",
    },
    blackList: {
      items: ["apellido_materno", "apellido_paterno", "nombre"],
      loan: ["solicitud"],
    },
    detail: { items: ["detalle_ocupacion"] },
    items: [],
    view: {
      pledges: false,
      aval: false,
      income: false,
      ref: false,
    },
    loan: {
      details: {
        nombre: "Nombre",
        apellido_paterno: "Apellido paterno",
        apellido_materno: "Apellido materno",
        fecha_nacimiento: "Fecha de nacimiento",
        estado_civil: "Estado civil",
        clave_elector: "Clave de elector",
        telefono_fijo: "Telefono fijo",
        telefono_movil: "Telefono movil",
        email: "Correo",
        ocupacion: "Ocupacion",
        detalle_ocupacion: "Detalle de ocupacion",
        tipo_casa: "Tipo de casa",
      },
      address: {
        calle: "Calle",
        num_ext: "Numero de exterior",
        num_int: "Numero de interior",
        entre_calles: "Entre calles",
        referencia: "Referencia",
        colonia: "Colonia",
        sector: "Sector",
        cp: "C.P.",
        municipio: "Municipio",
        estado: "Estado",
        pais: "Pais",
      },
      employee: {
        nombre: "Nombre",
        telefono: "Telefono",
      },
      income: {
        solicitud: "Solicitud",
        ingresos_negocios: "Ingresos sde negocios",
        ingresos_empleos: "Ingreso de empleo",
        total_ingresos_semanal: "Total de ingresos semanales",
        total_gastos_semanal: "Total de gastos semanales",
      },
      observation: {
        observaciones: "Observaciones",
      },
      pledge: {
        nombre: "Nombre",
        marca: "Marca",
        modelo: "Modelo",
        tipo: "Tipo",
        ano: "Año",
        color: "Color",
        numero_serie: "Numero de serie",
        numero_motor: "Observaciones",
      },
    },
    dialogs: {
      send: false,
      alert: false
    },
    rules: {
      comentarios: [(v) => !!v || "El campo es requerido", (v) => (v && v.length <= 2000) || "El campo excede la longitud máxima"],
    },
    forms: {
      send: {
        id: "",
        comentarios: "",
        rebajes: [],
      },
    },
    requestSections:{
      datos_autorizacion: false,
      datos_solicitante: false,
      persona_ingresos: false,
      avales: false,
      referencias_personales: false,
      ingresos_promedios: false,
      prendas: false,
      observaciones: false,
      documentos: false,
    },
    loading: false,
    modalType: false,
    questionMode: true,
    logo: require("@/assets/logo.png"),
    auxValue: [],
    deleteGlobals: false,
  }),
  computed: {
    perMoney: function () {
      let value = 0;
      if (this.items.general) {
        let suggested = parseFloat(this.items.general.monto_sugerido);
        let requested = parseFloat(this.items.general.monto_solicitado);
        value = (suggested / requested) * 100;
        if (isNaN(value)) {
          value = 0;
        }
      }

      return value;
    },
  },
  mounted() {
    this.loanId = parseInt(this.$route.params.id, 10);
    this.getDetail(this.$route.params.id);
  },
  methods: {
    getDetail(id) {
      this.loading = true;
      let request = {
        params: {
          id: id,
        },
      };
      this.axios
        .get(services.routes.loan + "/detail", request)
        .then((response) => {
          let data = response.data.data;
          this.items = data;
        })
        .catch((error) => {
          this.alert.active = true;
          this.alert.color = "error";
          this.alert.msg = error.response.data.message || "Servicio no disponible";
        })
        .finally(() => {
          this.completeSection()
          this.loading = false;
        });
    },
     completeSection() {
        for (let key of this.items.formularios_completados) {
          if(!this.requestSections[key]){
            this.requestSections[key] = true
          }
        }
        this.dialogs.send = false;
      },
          cancel() {
      if (this.dialogs.send) {
        this.$refs.formSend.reset();
        for (let key in this.forms.send) {
          this.forms.send[key] = "";
        }
        this.dialogs.send = false;
      }
    },
    save() {
      let dup = _.uniqBy(this.auxValue, 'key')
      if(dup.length == this.auxValue.length){
      if (this.$refs.formSend.validate()) {
        this.loading = true;
        this.forms.send.rebajes = this.auxValue;
        this.forms.send.id = this.loanId;
        if(this.modalType){
          this.sendData()
        }else{
          this.rejectData()
        }
        } else {
        this.alert.active = true;
        this.alert.color = "error";
        this.alert.msg = "La información es incorrecta";
      }
      }else {
        this.alert.active = true;
        this.alert.color = "error";
        this.alert.msg = "No se permiten los nombres duplicados";
      }
    },
    sendData(){
      let data = JSON.parse(JSON.stringify(this.forms.send));
      this.axios
          .put(services.routes.loan + "/send", { data })
          .then(() => {
            this.cancel();
            this.alert.active = true;
            this.alert.color = "success";
            this.alert.msg = "Solicitud " + (this.modalType ? "aprobada" : "rechazada") + " exitosamente";
          })
          .catch((error) => {
            this.alert.active = true;
            this.alert.color = "error";
            this.alert.msg = error.response.data.message || "Servicio no disponible";
          })
          .finally(() => {
            this.loading = false;
          });
    },
    rejectData(){
      let data = JSON.parse(JSON.stringify(this.forms.send));
      this.axios
          .post(services.routes.loan +"/reject", { data })
          .then(() => {
            this.cancel();
            this.alert.active = true;
            this.alert.color = "success";
            this.alert.msg = "Solicitud " + (this.modalType ? "aprobada" : "rechazada") + " exitosamente";
          })
          .catch((error) => {
            this.alert.active = true;
            this.alert.color = "error";
            this.alert.msg = error.response.data.message || "Servicio no disponible";
          })
          .finally(() => {
            this.loading = false;
          });
    },
    getValidate() {
      this.loading = true;
      let request = {
        params: {
          solicitud: this.loanId,
        },
      };
      this.axios
        .get(services.routes.alert, request)
        .then((response) => {
          this.alertData = response.data.data
          if(this.modalType && this.alertData.length > 0){
            this.dialogs.alert = true;
          }else{
            this.save()
          }
        })
        .catch((error) => {
          this.alert.active = true;
          this.alert.color = "error";
          this.alert.msg = error.response.data.message || "Servicio no disponible";
        })
        .finally(() => {
          this.loading = false;
        });
    },
    openModal(type) {
      this.modalType = type;
      this.dialogs.send = true;
      this.questionMode = true;
    },
    deleteGlobal(){
      let aux = [];
      for (let i = 0; i < this.auxValue.length; i++) {
        if(!this.auxValue[i].delete){
          aux.push(this.auxValue[i])
        }
      }
      this.auxValue = aux
    },
    addGlobal(){
      this.auxValue.push({
          delete: false,
          monto: 0,
          tipo: '',
        });
    },
    },
};
</script>

<style scoped>
.titulo {
  background: #222665;
  color: white;
  padding: 15px;
  display: inline-block;
  border-radius: 0px 0px 20px 20px !important;
  margin-bottom: 1px;
}
</style>
